import vueRouter from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getLocalStorage } from './utils/index'

NProgress.configure({ showSpinner: false })
const whiteList = ['/scope', '/remind', '/pay', '/home', '/login', '/agree']

vueRouter.beforeEach((to, from, next) => {

  NProgress.start()
  // setLocalStorage('toke', 'bearer 48704182-9987-4c5a-956d-80148b2a571e')
  const token = 'bearer f87cf832-95d1-4291-83d9-cee7fd9ca108'
  // const token = getLocalStorage('SET_TOKEN')

  if (whiteList.indexOf(to.path) !== -1) {
    next()
  } else {
    if (token) {
      if (to.path === '/pay') {
        next({ path: '/' })
        NProgress.done()
      } else {
        next()
      }
    } else {
      if (to.path === '/pay') {
        next()
      } else {
        NProgress.done()
        next({
          path: '/pay',
          query: {
            // redirect: to.fullPath
          }
        })
      }
    }
  }
})

vueRouter.afterEach(() => {
  NProgress.done()
})
