import axios from '../http.js'
// import qs from 'qs' // 根据需求是否导入qs模块

const camper = {
  // 参数规则
  rule (params) {
    return axios.get('/research/learn/dict/list', params)
  },

  // 营员列表
  list (params) {
    return axios.get('/app/contacts/list', params)
  },

  // 民族
  nation (params) {
    return axios.get('/dict/item/listByCode/nation', params)
  },

  // 营期列表
  promotion (params) {
    return axios.post('/promotion/select', params)
  },

  // 项目详情
  detail (params) {
    return axios.get('/research/learn/detail', { params })
  },

  // 添加营员
  add (params) {
    return axios.post('/app/contacts/add', params)
  },

  // 编辑营员
  edit (params) {
    return axios.post('/app/contacts/update', params)
  },

  // 报名表单限制条件
  astrict (params) {
    return axios.get(`/research/learn/dict/list/${params}`)
  },

  // 查询合同
  contract (params) {
    return axios.get(`/research/learn/getContByCampId/${params}`)
  },

  // 服务协议
  agreement (params) {
    return axios.get(`/unauth/agreement/content/get/type/${params}`)
  }
}

export default camper
