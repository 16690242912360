const mutations = {
  /** 名称 */
  SET_USER_NAME (state, data) {
    state.userName = data
  },

  /** token */
  SET_TOKEN (state, data) {
    state.userToken = data
  },

  /** providerUserId */
  SET_PROVIDER_USER_ID (state, data) {
    state.providerUserId = data
  },

  /** userId */
  SET_USER_ID (state, data) {
    state.userId = data
  },

  /** 营员 */
  SET_SELECT_CAMPER (state, data) {
    state.selectCamper = data
  },

  SET_BUSINESS_NAME (state, data) {
    state.businessName = data
  }
}

export default mutations
