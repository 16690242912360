import { getLocalStorage } from '@/utils'

const state = {
  userName: '' || getLocalStorage('SET_USER_NAME'),
  userToken: '' || getLocalStorage('SET_TOKEN'),
  userId: '' || getLocalStorage('SET_USER_ID'),
  providerUserId: '' || getLocalStorage('SET_PROVIDER_USER_ID'),
  selectCamper: [],
  businessName: '' || getLocalStorage('SET_BUSINESS_NAME')
}

export default state
