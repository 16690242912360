import axios from '../http.js'

const order = {
  // 提交订单
  submitOrder (params) {
    return axios.post('/order/mall/addOrder?method=order-param-summer-camp', params)
  },

  // 根据订单id获取支付参数
  citicpay (params) {
    return axios.post('/citicpay/pay', Object.keys(params).map(v => `${v}=${encodeURI(params[v])}`).join('&'), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },

  // 检查支付状态
  payStatus (params) {
    return axios.get('/citicpay/query/order/payment', { params })
  },

  // 订单列表
  getOrderLists (params) {
    return axios.get('/order/mall/getMiniOrderMallList', { params })
  },

  // 订单详情
  orderDetail (params) {
    return axios.get('/order/mall/getOrderMallDetail', { params })
  },
  // 取消订单
  cancelOrder (params) {
    return axios.get('/order/mall/miniAppCancelOrder', { params })
  },
  // 获取客服电话
  getTell () {
    return axios.get('/common/settings/get')
  },

  /** 创建订单 */
  create (params) {
    return axios.post('/syf/addPayOrder', params)
  }
}

export default order
