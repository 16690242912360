<template>
  <div class="popup">
    <van-popup
      v-model:show="showPopup"
      position="left"
      closeable
      close-icon-position="top-right"
      @click-close-icon="handleCloseIcon"
      @click-overlay="handleCloseOverlay"
      :style="{ height: '100%', width: '76%' }"
    >
      <div class="mine">
        <div class="mine-user">
          <img src="../../assets/images/public/user.png" alt="头像" />
          <p class="user-name">欢迎您</p>
        </div>
        <div class="mine-content">
          <div class="title">我的服务</div>

          <div class="service-common">
            <div class="operate" @click="handleOrder">
              <div class="icon">
                <img src="../../assets/images/public/m_about.png" alt="">
              </div>
              <span>我的订单</span>
            </div>
            <!--<div class="operate">
              <div class="icon">
                <img src="../../assets/images/public/m_about.png" alt="">
              </div>
              <span>设置</span>
            </div>-->
          </div>
        </div>

        <div class="mine-bottom">
          <van-button type="primary" class="sure" block @click="loginOut">退出登录</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { removeLocalStorage } from '@/utils'

export default {
  name: 'LeftMine',
  props: {
    showMineStatus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showPopup: {
      get () {
        return this.showMineStatus
      },
      set (newValue) {
        console.log(newValue)
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    handleCloseIcon () {
      this.$emit('sendEvent', false)
    },

    handleCloseOverlay () {
      this.$emit('sendEvent', false)
    },

    handleOrder () {
      this.$router.push({
        path: '/activity'
      })
    },

    /** 退出 */
    loginOut () {
      this.$dialog.alert({
        title: '温馨提示',
        message: '确定要退出吗？',
        confirmButtonColor: '#1A7AB5',
        showCancelButton: true
      }).then(() => {
        removeLocalStorage('PROJECT_ID')
        window.localStorage.clear()
        window.sessionStorage.clear()
        this.$router.replace('/scope')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixin";

.popup {
  max-width: 750px;
  .mine {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    .mine-user {
      @include CCFS;
      padding-top: 20%;
      padding-bottom: 30px;
      img {
        @include wh(130px, 130px);
        border-radius: 50%;
        overflow: hidden;
      }
      .user-name {
        padding-top: 30px;
        @include sc(32px, $twoFontColor);
        @include textOverFlow(1)
      }
    }
    .mine-content {
      flex: 1;
      padding: 30px;
      .title {
        padding: 30px 0;
        @include sc(30px, $twoFontColor);
        font-weight: bold;
      }

      .service-common {
        @include RCSA;
        .operate {
          padding: 15px 25px 15px;
          @include CCC;
          img {
            @include wh(88px, 88px);
          }
          span {
            display: block;
            text-align: center;
            padding-top: 13px;
            @include sc(30px, $mainFontColor);
          }
        }
        .operate:active {
          background: #f5f6f7;
        }
      }
    }

    .mine-bottom {
      padding: 10%;
      .sure {
        background: $themeColor;
        border: none;
        border-radius: 50px;
      }
    }
  }
}
</style>
