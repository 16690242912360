import axios from '../http.js'
import base from './base.js'

const Home = {
  example (params) {
    return axios.post(base.sBaseApi + '/sms/fangwei/send', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },

  postMerchInfo (merchId) {
    return axios.post(`/syf/getByMchId?mchId=${merchId}`, {})
  }
}

export default Home
