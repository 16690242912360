import { Toast } from 'vant'
import { setLocalStorage } from '@/utils'
import Login from '../api/api/login'
import Order from '../api/api/order'
import Home from '../api/api/home'

const actions = {
  /** 用户登录 */
  LoginByUserName ({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      Login.login(userInfo).then(res => {
        resolve(res)
        commit('SET_USER_NAME', res.username)
        setLocalStorage('SET_USER_NAME', res.username)

        commit('SET_TOKEN', `${res.token.token_type} ${res.token.access_token}`)
        setLocalStorage('SET_TOKEN', `${res.token.token_type} ${res.token.access_token}`)

        commit('SET_PROVIDER_USER_ID', res.providerUserId)
        setLocalStorage('SET_PROVIDER_USER_ID', res.providerUserId)
        commit('SET_USER_ID', res.user_id)
        setLocalStorage('SET_USER_ID', res.user_id)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /** 用户登录2 */
  LoginByUserName2 ({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      Login.register(userInfo).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /** 获取支付参数 */
  GetPayParams ({ commit }, data) {
    return new Promise((resolve, reject) => {
      Order.citicpay(data).then(res => {
        if (res.appId) {
          resolve(res)
        } else {
          Toast(res.msg || res.data || '获取支付参数失败')
        }
      }).catch(err => {
        reject(err)
      })
    })
  },

  /** 获取商户信息 */
  GetBusinessInfo ({ commit }, data) {
    return new Promise((resolve, reject) => {
      Home.postMerchInfo(data).then(res => {
        commit('SET_BUSINESS_NAME', res.shortName || res.name)
        setLocalStorage('SET_BUSINESS_NAME', res.shortName || res.name)

        Toast(res.msg || res.data || '获取商户信息失败')
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default actions
