/**
 * axios请求模块
 * */
import axios from 'axios'
import store from '../store/index'
import router from '../router'
import base from './api/base'
// import { guid } from '../utils'
import { Dialog, Toast } from 'vant'
let isRefreshing = false

/**
 * 公共提示
 */
const tip = msg => {}

/**
 * 请求loading
 */
const loadingText = () => {
  Toast.loading({
    duration: 0,
    forbidClick: true,
    message: '加载中...'
  })
}

/**
 * 跳转登录页
 */
const toLogin = () => {
  router.replace({
    path: '/scope',
    query: {
      // redirect: router.currentRoute.fullPath
    }
  })
}

/**
 * 请求失败后的错误统一处理
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    // 401: 未获取到登录状态，跳转登录页
    // 返回 401 清除token信息并跳转到登录页面
    case 401:
      //  store.commit(types.LOGOUT); 清除token信息
      toLogin()
      break

    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      tip('登录过期，请重新登录')
      // localStorage.removeItem('token')
      // store.commit('loginSuccess', null)
      setTimeout(() => {
        toLogin()
      }, 1000)
      break

    // 404请求不存在
    case 404:
      tip('请求的资源不存在')
      break
    default:
      console.log(other)
  }
}

/**
 * 创建axios实例
 */
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 12,
  withCredentials: true
})

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  config => {
    loadingText()
    // config.headers.tenantId = base.tenantIds.tenantIdCustom
    if (store.state.userToken) {
      config.headers.Token = `${store.state.userToken}`
    }
    return config
  },
  error => Promise.reject(error)
)

/**
 * 响应拦截器
 */
instance.interceptors.response.use(response => {
  Toast.clear()
  if (response.status === 200) {
    if (response.data.code === '401') {
      if (!isRefreshing) {
        isRefreshing = true
        Dialog.alert({
          confirmButtonColor: '#1487cd',
          message: '登录过期，请重新登录'
        }).then(() => {
          isRefreshing = false
          window.localStorage.clear()
          toLogin()
        })
      }
    } else if (response.data.success) {
      return response.data.data
    } else {
      return response.data
    }
  } else {
    return Promise.reject(response.data)
  }
}, error => {
  Toast.clear()
  const { response } = error
  if (response) {
    errorHandle(response.status, response.data.message)
    return response
  } else if (error.message.indexOf('Timeout') > -1) {
    return Promise.reject('客户端请求超时')
  } else {
    return Promise.reject(response.data)
  }
})

export default instance
