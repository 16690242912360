import axios from '../http.js' // 导入http.js创建的实例
// import qs from 'qs' // 根据需求是否导入qs模块
import base from './base.js'

const login = {
  code (params) {
    return axios.get('/wechatUser/sendValidateCode', { params })
  },

  login (params) {
    return axios.post(`/login?method=${params.method}`, params.params, {
      headers: {
        'service-version': '20190923',
        from: '2'
      }
    })
  },

  register (params) {
    return axios.post(`/register?method=${params.method}`, params.params, {
      headers: {
        'service-version': '20190923',
        from: '2'
      }
    })
  }
}

export default login
