<template>
  <div class="swiper_widget">
    <van-swipe :autoplay="3000" lazy-render :show-indicators="false" :loop="false" :style="{height: height + 'rem'}" class="banner_swipe">
      <van-swipe-item v-if="video && video !== ''">
        <img class="banner_img" :src="mainImage" alt=""/>
      </van-swipe-item>
      <template v-if="!carouselImages.length">
        <van-swipe-item v-for="(item, index) in carouselImages" :key="index">
          <img class="banner_img" :src="item.url" alt=""/>
        </van-swipe-item>
      </template>
      <van-swipe-item v-if="!carouselImages.length && !video">
        <van-image
          cover
          show-loading
          show-error
          width="100%"
          height="100%"
          lazy-load
          :src="mainImage"
        />
        <!--<img class="banner_img" :src="mainImage" alt=""/>-->
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    bannerData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: {
      type: Number,
      default: () => 7.8
    }
  },

  data () {
    return {
      video: '',
      mainImage: '',
      carouselImages: []
    }
  },

  watch: {
    bannerData: function (newValue, oldValue) {
      this.video = newValue.video || ''
      this.mainImage = newValue.mainImage || ''
      this.carouselImages = newValue.carouselImages || []
    }
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixin";
.swiper_widget {
  .banner_swipe {
    //height: 780px;
    .banner_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
