<template>
  <div class="button-public">
    <button @click="clickBtn" :class="!changBg?'':'blue'">{{title}}</button>
  </div>
</template>

<script>
export default {
  name: 'ButtonPublic',
  props: {
    title: {
      type: String,
      default () {
        return '确定'
      }
    },

    changBg: {
      type: Boolean,
      default () {
        return false
      }
    },

    params: {
      type: String,
      default () {
        return ''
      }
    }
  },
  methods: {
    clickBtn () {
      this.$emit('transParent', this.params)
      // this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixin";
.button-public {
  width: 100%;
  button {
    display: block;
    @include wh(100%, 88px);
    @include sc(32px, #fff);
    line-height: 88px;
    background: $themeColor;
    border-radius: 44px;
  }
  button:active {
    background: $themeColorActive;
  }
  .blue {
    background: #adadad;
  }
}
</style>
