import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home/index'
// import activityOrder from './module/activityOrder'
// import camper from './module/camper'

const routes = [
  // ...camper,
  // ...activityOrder,
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index')
  },
  {
    path: '/scope',
    name: 'Scope',
    component: () => import('../views/login/scope')
  },
  {
    path: '/:id?',
    name: 'Pay',
    component: () => import('../views/pay/index.vue'),
    meta: {
      title: '支付',
      keepAlive: false
    }
    // redirect: '/pay'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页',
      footShow: true
    }
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('../views/mine/index'),
    meta: {
      title: '我的',
      footShow: true
    }
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('../views/mine/setting/index'),
    meta: {
      title: '设置'
    },
    children: [
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/mine/about/index'),
        meta: {
          title: '关于我们'
        }
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      title: '关于我们',
      keepAlive: true
    }
  },
  /* {
    path: '/pay', // /:id?
    name: 'Pay',
    component: () => import('../views/pay/index.vue'),
    meta: {
      title: '支付',
      keepAlive: false
    }
  }, */
  {
    path: '/remind',
    name: 'Remind',
    component: () => import('../views/error/index.vue'),
    meta: {
      title: '扫码工具异常',
      keepAlive: false
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/error/404.vue'),
    meta: {
      title: '页面走丢了'
    }
  },
  {
    path: '/:pathMatch(.*)*', // 匹配所有路径 放最后
    redirect: {
      name: '404'
    }
  }
]
// base 选项用于设置路由的基础路径，history 选项用于创建历史记录管理器
// 通常，base 选项应该与 publicPath 保持一致，以确保路由路径和静态资源路径的正确性
const router = createRouter({
  history: createWebHistory(), // process.env.VUE_APP_BASE_UR
  // base: process.env.VUE_APP_BASE_URL,
  // history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop
      }
      return { x: 0, y: to.meta.savedPosition || 0 }
    }
  }
})

export default router
