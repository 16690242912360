import { getLocalStorage, urlToObj } from '@/utils'
import { aliAppId } from '@/utils/config'
// import AlipayJSSDK from 'alipay-jssdk'

export default {
  data () {
    return {
      codeParams: {},
      form: {}
    }
  },
  methods: {
    /** 或者 */
    onHandleAliPay () {
      this.$http.XXX({}).then(res => {
        this.$http.xxx({
          oid: res.data, // 取到的交易订单号
          url: '' // 成功后的回调地址
        }).then(res => {
          // 避免时间间隙造成的用户误操作，尽管拿到数据了仍然显示loading...直到跳转到支付宝的提供的页面
          this.$toast.loading({
            mask: true,
            message: '加载中...'
          })
          // 取回来的是支付宝提供的一段自执行的form表单代码
          // 这里我把这段代码插入页面中，并手动触发
          const div = document.createElement('div')
          div.innerHTML = res.data.form
          document.body.appendChild(div)
          document.forms[0].submit()
        })
      })
    },

    /** 获取Ali code */
    getAliCode () {
      const urlNow = encodeURIComponent(window.location.href)
      window.location.href = `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=${aliAppId}&scope=auth_user&redirect_uri=${urlNow}`
    },

    /** 支付 */
    handleAliPay () {
      if (this.out_trade_no) {
        if (window.AlipayJSBridge) {
          this.alPay()
        } else {
          document.addEventListener('AlipayJSBridgeReady', this.alPay, false)
        }
      } else {
        alert(123)
        // this.getAliCode()
      }
    },

    alPay () {
      AlipayJSBridge.call('tradePay', { tradeNO: this.out_trade_no },
        function (result) {
          alert(JSON.stringify(result))
          /* if (result.resultCode == '9000') {
            this.$router.push('./recharge-result?rechargeResult=success')
          } else {
            this.$router.replace('./recharge-result?rechargeResult=fail')
          } */
          // window.AlipayJSBridge.call('closeWebview')
        }
      )
    }
  }
}
