/**
 * 接口域名的管理
 */

const base = {
  baseUrl1: 'http://prod.zzwl.luxincloud.com/api',
  baseUrl2: 'http://prod.zzwl.luxincloud.com/mallapi',
  sBaseApi: 'saas-base-api',
  sBoxApi: 'saas-box-api',
  sShopApi: 'saas-shop-api',
  tenantIds: {
    tenantIdCustom: '10000'
  }
}

export default base
