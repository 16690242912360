import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import FastClick from 'fastclick'
import './permission'
import http from './api/api'
import './assets/iconfont/iconfont.css'
import './utils/flexible'
import dir from './utils/directive'

import Vant, { Lazyload } from 'vant'

import 'vant/lib/index.css'

// FastClick的ios点击穿透解决方案
FastClick.prototype.focus = function (targetElement) {
  let length
  if (targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month') {
    length = targetElement.value.length
    targetElement.focus()
    targetElement.setSelectionRange(length, length)
  } else {
    targetElement.focus()
  }
}
FastClick.attach(document.body)

const app = createApp(App)
app.directive('stopReClick', dir.stopReClick)

app.use(Vant).use(Lazyload)
app.config.globalProperties.$http = http

app.use(store).use(router).mount('#app')
