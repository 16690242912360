/**
 * api接口的统一出口
 */

import login from './login'
import Home from './home'
import camper from './camper'
import order from './order'
import pay from './pay'

export default {
  Home,
  camper,
  order,
  login,
  pay
}
