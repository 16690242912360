<template>
  <div>
    <div class="w750 no_data" v-show="!projectId">
      <van-empty image-size="5rem" description="请扫描营种二维码" />
    </div>
    <div v-show="projectId" class="home_container">
      <div v-show="token" class="tops" :style="{backgroundColor: bacColor}">
        <span :style="{opacity: opacity}"></span>
        <div class="right" @click="openMine">
          <img src="../../assets/images/public/mine_photo.png" alt="">
        </div>
      </div>

      <div class="banner_container">
        <swiper-widget :bannerData="bannerData" :height="7.8"/>
        <div class="price">
          <i>¥</i>{{ this.formatPrice(info.basePrice) }}
        </div>
      </div>

      <div class="section">
        <div class="product_container">
          <div class="title">{{ info.name }}</div>
          <div class="desc">{{ info.introduction }}</div>
          <ul>
            <li>
              <span class="s1">招生对象</span>
              <span class="s2">{{this.formatObj(info.suitableAge, info.lowerAgeLimit, info.upperAgeLimit)}}</span>
            </li>
            <li>
              <span class="s1">研学出发地</span>
              <span class="s2">{{ info.departureCity }}</span>
            </li>
            <li>
              <span class="s1">营会天数</span>
              <span class="s2">{{ info.journeyDays }}天</span>
            </li>
          </ul>
          <!-- <div class="date_content">
            <div class="title">选择营期&营员</div>
            <div class="date">7月、8月、9月</div>
          </div> -->
        </div>
        <div class="rich_container">
          <div class="title">行程<em>安排</em></div>
          <div v-html="info.itineraryDetails" />
          <!-- <img :src="bannerData.mainImage" alt=""> -->
        </div>
        <div class="notice_container common_container">
          <div class="title">费用须知</div>
          <div class="desc" v-html="info.feeNotice" />
        </div>
        <div class="tip_container common_container">
          <div class="title">温馨提示</div>
          <div class="desc" v-html="info.reminder" />
        </div>
      </div>

      <left-mine :showMineStatus="showMineStatus" @sendEvent="handleAcceptEvent"/>

      <div class="bottom">
        <button-public @transParent="handleSubmit" :changBg="info.status!=='10'" :title="shopStatusTitle" />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonPublic from '@/components/module/ButtonPublic'
import SwiperWidget from '@/components/module/SwiperWidget'
import LeftMine from '@/views/home/LeftMine'
import { getLocalStorage, setLocalStorage } from '@/utils'

export default {
  name: 'Home',
  components: {
    ButtonPublic,
    SwiperWidget,
    LeftMine
  },
  computed: {
    shopStatusTitle () {
      let title = ''
      if (this.info) {
        if (this.info.status === '10') {
          title = '立即报名'
        } else if (this.info.status === '20') {
          title = '营种已下架'
        }
      }

      return title
    }
  },
  data () {
    return {
      projectId: '',
      token: '',
      bannerData: {},
      banners: [],
      info: {},
      // shopStatusTitle: '',
      bacColor: '',
      opacity: 0,
      showMineStatus: false
    }
  },
  mounted () {
    const urlObj = this.$route.query
    const projectId = getLocalStorage('PROJECT_ID')
    this.token = getLocalStorage('SET_TOKEN')
    console.log(urlObj, projectId)
    if (!Object.keys(urlObj).length) {
      // eslint-disable-next-line eqeqeq
      if (Number(projectId)) {
        this.getProjectDetail(projectId)
        this.projectId = projectId
      } else {
        this.projectId = ''
      }
    } else {
      setLocalStorage('PROJECT_ID', urlObj.id)
      this.getProjectDetail(urlObj.id)
      this.projectId = urlObj.id
    }

    if (window.history && window.history.pushState) {}
    window.addEventListener('scroll', this.handleScroll)
  },

  methods: {
    getProjectDetail (id) {
      this.$toast.loading('玩命加载中...')
      this.$http.camper.detail({ id }).then(res => {
        this.$toast.clear()
        this.bannerData = {
          carouselImages: res.carouselImages === '' ? [] : res.carouselImages,
          mainImage: res.mainImage,
          video: res.video,
          videoCover: res.videoCover
        }
        this.info = res
      }).catch(() => {
        this.$toast.clear()
      })
    },

    formatObj (a, b, c) {
      if (a === '0') {
        return '年龄不限'
      } else if (a === '1') {
        return `建议${b}-${c}岁`
      } else if (a === '2') {
        return `限${b}-${c}岁`
      } else {
        return ''
      }
    },

    formatPrice (a) {
      if (!a) return '0.00'
      return a.toFixed(2)
    },

    openMine () {
      this.showMineStatus = true
    },

    handleAcceptEvent (val) {
      this.showMineStatus = false
    },

    handleScroll () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop) {
        if (scrollTop / 88 >= 1) {
          this.bacColor = 'rgba(25, 125, 183, 1)'
          this.opacity = 1
        } else {
          this.bacColor = `rgba(25, 125, 183, ${scrollTop / 88})`
          this.opacity = scrollTop / 88
        }
      } else if (scrollTop === 0) {
        this.opacity = 0
        this.bacColor = 'transparent'
      }
    },

    handleSubmit () {
      if (this.info && this.info.status === '10') {
        if (this.info.id) {
          const params = {
            productId: this.info.id,
            name: this.info.name,
            price: this.info.basePrice.toFixed(2),
            mainImage: this.info.mainImage,
            type: this.info.type,
            date: this.info.productPrices
          }
          this.$router.push({
            path: '/camper',
            query: {
              date: JSON.stringify(params)
            }
          })
        } else {
          this.$toast('参数错误')
        }
      }
    }
  },

  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/mixin";
  .no_data {
    height: 100vh;
    @include CCC;
  }
  .home_container {
    .tops {
      @include RCC;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      max-width: 750px;
      margin: 0 auto;
      height: 98px;
      z-index: 100;
      padding: 0 30px;
      span {
        color: #fff;
      }
      .right {
        position: absolute;
        right: 32px;
        top: 24px;
        img {
          @include wh(50px, 50px);
        }
      }
    }
  }
  .banner_container {
    .price {
      padding: 0 40px;
      height: 120px;
      line-height: 120px;
      background: $themeColor;
      i {
        font-size: 24px;
      }
      @include sc(45px, #fff);
      font-weight: bold;
    }
  }
  .section {
    padding: 40px 40px 168px;
  }
  .product_container {
    .title {
      @include sc(32px, $oneFontColor);
    }
    .desc {
      @include sc(24px, $twoFontColor);
      line-height: 48px;
      margin-top: 30px;
      margin-bottom: 40px;
    }
    ul {
      @include RCSA;
      padding: 40px 20px;
      @include boxShadow;
      border-radius: 20px;
      background: white;
      li {
        position: relative;
        width: 33.333%;
      }
      li:nth-child(2):before {
        @include border-lpx();
      }
      li:nth-child(2):after {
        @include border-rpx()
      }
      span {
        display: block;
        line-height: 1;
        text-align: center;
      }
      .s1 {
        @include sc(24px, $twoFontColor);
      }
      .s2 {
        margin-top: 20px;
        @include sc(32px, $themeColor);
      }
    }
    .date_content {
      margin-top: 30px;
      padding: 20px;
      @include boxShadow;
      border-radius: 20px;
      background: white;
      .date {
        @include sc(24px, $themeColor);
        margin-top: 20px;
      }
    }
  }
  .rich_container {
    padding: 40px 0;
    .title {
      @include sc(38px, $oneFontColor);
      font-style: italic;
      font-weight: bold;
      margin-bottom: 30px;
      em {
        color: $themeColor;
      }
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .common_container {
    .title {
      @include sc(32px, $oneFontColor);
      font-weight: bold;
      margin-bottom: 20px;
    }
    padding: 20px;
    @include boxShadow;
    border-radius: 20px;
    background: white;
    .desc {
      @include sc(26px, $twoFontColor);
      line-height: 48px;
    }
  }
  .notice_container {
    margin-bottom: 40px;
  }
  .bottom {
    margin: 0 auto;
    @include RCC;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1500;
    max-width: 750px;
    height: 138px;
    padding: 0 40px;
    background: #fff;
  }
</style>
