<template>
  <div class="footer-nav">
    <ul>
      <li v-for="(item, index) in navigator" :key="index" @click.stop="goRoute(item.path, index)">
        <img :src="index === selected ? item.re : item.de" alt="">
        <span :class="index === selected ? 'active' : ''">{{item.name}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'footerNav',
  data () {
    return {
      selected: 0,
      navigator: [
        {
          name: '首页',
          de: require('@/assets/images/footer/f_home_d.png'),
          re: require('@/assets/images/footer/f_home.png'),
          path: '/home'
        },
        {
          name: '我的',
          de: require('@/assets/images/footer/f_mine_d.png'),
          re: require('@/assets/images/footer/f_mine.png'),
          path: '/mine'
        }
      ]
    }
  },
  created () {
    this.active()
  },
  methods: {
    active () {
      const type = this.$route.name
      const navName = ['Home', 'Mine']
      this.selected = navName.indexOf(type)
    },
    goRoute (path, index) {
      this.selected = index
      this.$router.push({
        path: path
      })
    }
  },
  watch: {
    $route () {
      this.active()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixin";
.footer-nav {
  background: #fff;
  position: fixed;
  z-index: 99;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 750px;
  height: 98px;
  margin: 0 auto;
  box-shadow: 0 -2px 6px rgba(5, 13, 28, 0.03);
  @include CCC;
  ul {
    @include RCSB;
    width: 100%;
    li {
      width: 100%;
      height: 88px;
      @include CCSA;
      span {
        display: block;
        font-size: 26px;
        color: #999;
        text-align: center;
        line-height: 1;
      }
      img {
        height: 32px;
        width: 32px;
      }
    }
  }
  .active {
    color: $themeColor;
    font-weight: bold;
  }
}
</style>
